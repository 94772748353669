import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content"];
  static classes = ["active"];
  static values = {
    current: String,
  };

  connect() {
    this.updateVisibility();
  }

  change(event) {
    console.log("change");
    this.updateVisibility(event);
  }

  updateVisibility(event) {
    if (event) {
      this.currentValue = event.target.value;
    }

    this.contentTargets.map((contentTarget) => {
      let setValues = contentTarget.dataset.toggleRadioSetValue;
      let resetValues = contentTarget.dataset.toggleRadioResetValue;

      if (setValues) {
        contentTarget.classList.toggle(
          this.activeClass,
          JSON.parse(setValues).includes(this.currentValue),
        );
      } else if (resetValues) {
        contentTarget.classList.toggle(
          this.activeClass,
          !JSON.parse(resetValues).includes(this.currentValue),
        );
      }
    });
  }
}
