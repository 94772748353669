import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["item", "input"];

  static values = {
    current: String,
    disabled: Boolean,
  };

  connect() {
    this.currentValue =
      this.inputTargets.find((input) => input.checked)?.value || "";

    this.render();
  }

  render() {
    this.itemTargets.forEach((element) =>
      element.parentElement.classList.toggle(
        "active",
        this.isSelected(element),
      ),
    );
  }

  onSelect(event) {
    console.log("select?");
    event.preventDefault();

    if (this.disabled) {
      return;
    }

    this.currentValue = event.currentTarget.dataset.value;
    const selectedInput = this.inputTargets.find(
      (input) => input.value === this.currentValue,
    );

    if (selectedInput) {
      selectedInput.checked = true;
      selectedInput.dispatchEvent(new Event("change", { bubbles: true }));
    }

    this.render();
  }

  isSelected(element) {
    return element.dataset.value === this.currentValue;
  }
}
