import { Controller } from "@hotwired/stimulus";
import { useClickOutside } from "stimulus-use";
import { toggle, leave } from "el-transition";

export default class extends Controller {
  static targets = ["menu", "overlay"];

  connect() {
    useClickOutside(this, { element: this.menuTarget });
  }

  toggle(event) {
    event.preventDefault();
    toggle(this.menuTarget);
    toggle(this.overlayTarget);
  }

  close() {
    leave(this.menuTarget);
    leave(this.overlayTarget);
  }

  clickOutside(event) {
    this.close();
  }
}
